import React from 'react';
import ClientModule from '@gqlapp/module-client-react';
import resources from './locales';
import menu, {} from "./menu";
import Action from '@vinhxuan/block-common/classes/Action';
import Route from '@vinhxuan/router/classes/Route';
import Category from "./containers/ListContainer";
import Photo from "./containers/PhotoContainer";
export default new ClientModule(menu, {
  localization: [{ ns: 'photo', resources, more: ['global'] }],
  route:{
    Photo:          <Route component={Photo}    {...{ classNameOnPage: 'photo', action: Action.instanse() }}/>,
    TaxonomyGallery:<Route component={Category} {...{ classNameOnPage: 'taxonomy taxonomy-gallery', action: Action.instanse() }}/>
  }
});

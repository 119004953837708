import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import Route from '@vinhxuan/router/classes/Route';
import resources from './locales';
import menu, {} from "./menu";
import Action from '@vinhxuan/block-common/classes/Action';
import List from "./containers/ListContainer";
import Video from "./containers/VideoContainer";


export default new ClientModule(menu, {
  route:{
    Video:        <Route component={Video} {...{ classNameOnPage: 'video', action: Action.instanse() }}/>,
    TaxonomyClip: <Route component={List}  {...{ classNameOnPage: 'taxonomy taxonomy-clip', action: Action.instanse() }}/>,
  },
  localization: [{ ns: 'video', resources, more: ['global'] }]
});


import React from 'react';
import withProps from 'recompose/withProps';
import Popup from "@gqlapp/base/Modal/Popup";
import dynamic from 'next/dynamic';

/**
import DataUserToolbars from './UserToolbars';
/*/
const ToolbarForm = dynamic(() => import('./UserToolbars').then((c) => c.default));

const DataUserToolbars = ({ isOpen, ...props }) => (
	<>{!__SERVER__ && isOpen && <><ToolbarForm {...{ ...props, isOpen }} /></>}</>);
/**/

export const WithOnUserToolbars = withProps((props) => {
	let ref = React.createRef();
	let onGlobalRenders = props?.onGlobalRenders || {}
	let onActions = props?.onActions || {}

	Object.assign(onActions, {
		UserToolbars: ((val) => {
			try { ref.current.open(val); } catch (e) {}
		})
	})

	Object.assign(onGlobalRenders, {
		UserToolbars: (rest) => (<>
			<Popup
				{...props}
				{...rest}
				ref={ref}
				component={DataUserToolbars}
			/>
		</>)
	})

	return {
		onGlobalRenders,
		onActions
	}
});

export default WithOnUserToolbars;

import React from 'react';
import ClientModule from "@gqlapp/module-client-react";

// @ts-ignore
import resources from './locales';
import RootComponent from './containers/RootComponent';


export default new ClientModule({

  // @ts-ignore
  rootComponentFactory: [req => <RootComponent req={req} />],
  localization: [{ ns: 'user', resources, more: ['common'] }]
});

import Entities from '@gqlapp/core-common/Entities';
import Video from './Video';

export default class Videos extends Entities {
  constructor(props={}) {
    super(props);
    this.interface = Video;
  }
  
  /**
   * instanse
   */
  public static instanse(props={}) {
    return new this(props);
  }
}
import { graphql } from 'react-apollo';
import {compose, withProps } from 'recompose';
import mapInput from '@gqlapp/base/utils/mapInput';
import { MENU_QUERY } from "../graphql";

export const withMenus  = (input:any)=> (Component:any) => compose(
  withProps((props:any) => mapInput(input, props)),
  graphql(MENU_QUERY, {
    options: ({ name }:any) => ({ variables: { name }}),
    props({ data: { loading, menu:contents, refetch, error, updateQuery, fetchMore, subscribeToMore }, ownProps:{ menu } }:any) {
      if(!loading && contents){ menu.save(contents); }
      return { loading, menusRefetch:refetch, menusMore: fetchMore, menusSubscribeToMore: subscribeToMore, updateQuery, errors: error ? error.graphQLErrors : null };
    }
  }))(Component);


class Action{
  constructor(props: any = null) {}

  /**
   *
   */
  public static instanse(props: any = {} ) {
    return (new this(props));
  }

  public test(){

  }

  _callback: any = ()=> { };

  set callback(value: any){
    this._callback = value
  }

  get callback(){
    return this._callback;
  }
}

export default Action;

import React from 'react';
import withProps from 'recompose/withProps';
import Popup from "@gqlapp/base/Modal/Popup";
import dynamic from 'next/dynamic';

const ToolbarForm = dynamic(() => import('./Search').then((c) => c.default));

const DataSearch = ({ isOpen, ...props }) => (
	<>{!__SERVER__ && isOpen && <ToolbarForm {...{ ...props, isOpen }} />}</>);

export const WithOnSearch = withProps((props) => {
	let ref = React.createRef();
	let onGlobalRenders = props?.onGlobalRenders || {}
	let onActions = props?.onActions || {}

	let Render = (rest) => (<>
		<Popup
			{...props}
			{...rest}
			ref={ref}
			component={DataSearch}
		/>
	</>);

	let Action = (val) => (()=> {
		try { ref.current.open(val); } catch (e) {}
	})

	Object.assign(onActions, {
		Search: Action
	})

	Object.assign(onGlobalRenders, {
		Search: Render
	})

	return {
		onGlobalRenders,
		onActions
	}
});

export default WithOnSearch;

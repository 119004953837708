import moment from 'moment';
import settings from '@vinhxuan/config'
class Profile {
  // tslint:disable-next-line:variable-name
  private _name: string = '';
  // tslint:disable-next-line:variable-name
  public first_name: string = '';
  // tslint:disable-next-line:variable-name
  public last_name: string = '';

  public birthday: any = moment();
  public sex: string = 'other';

  set name(value) {
    this._name = value;
  }

  get name() {
    return this._name || '';
  }

  constructor({ name, first_name, last_name, birthday, sex }: any) {
    this.name = name;
    this.first_name = first_name;
    this.last_name = last_name;
    this.birthday = birthday;
    this.sex = sex || 'other';
  }
}

class Address {
  public country: string;
  public zone: string;
  public locality: string;
  public dependentlocality: string;
  public address: any;
  // tslint:disable-next-line:variable-name
  public address_2: string;

  constructor({ country, zone, locality, dependent_locality, address, address_2 }: any) {
    this.country = country;
    this.zone = zone;
    this.locality = locality;
    this.dependentlocality = dependent_locality;
    this.address = address;
    this.address_2 = address_2;
  }
}

class Auth {
  public facebook: any = {};
  public google: any = {};
  constructor({ facebook, google }: any) {
    this.facebook = facebook || {};
    this.google = google || {};
  }
}

export class User {
  public id: number | undefined;
  public mail: string | undefined;
  public name: string | undefined;
  public username: string | undefined;
  public image: string | undefined;

  private _roles: any[] = [];
  // tslint:disable-next-line:variable-name
  private _is_active: boolean | undefined;
  private _phone: string | undefined;
  private _area: string | undefined;
  private _birthday: string | undefined | null;
  private _sex: boolean | undefined;

  private _address = new Address({});
  private _profile = new Profile({});
  private _auth = new Auth({});


  private _accessUpdate         : boolean | undefined;
  private _accessDelete         : boolean | undefined;

  public photo: string | undefined;
  public ga: string | undefined;
  public pixel: string | undefined;
  public perms: any;

  /**
   * instanse
   */
  public static instanse(data?:any) {
    return new this().save(data || {});
  }

  get address() {
    return this._address;
  }

  set address(address: any) {
    this._address = new Address(address || {});
  }

  /**
   * profile
   */
  get profile() {
    return this._profile;
  }
  set profile(profile) {
    this._profile = new Profile(profile || {});
  }

  get auth() {
    return this._auth;
  }
  set auth(auth) {
    this._auth = new Auth(auth || {});
  }


  get display_name() {
    return this.profile.name || '';
  }

  get first_name() {
    return this.profile.first_name || '';
  }

  get last_name() {
    return this.profile.last_name || '';
  }


  set phone(value: string) {
    try{

      let { phone:code, value: area } = ([...settings.country.countries]
          .filter(({ phone:code }: any)=>
              new RegExp(`^${code}`)
                  .test(value.replace(/\D/g, ""))
          )).shift();
      this._area = area;
      this._phone = value
          .replace(/\D/g, "")
          .replace(new RegExp(`^${code}`), '')
    }catch (e) {}
  }

  get area() { return this._area || 'VN';}

  get phone() { return this._phone || ''; }

  set birthday(value) {
    // @ts-ignore
    this._birthday = value;
  }
  get birthday() {
    return this._birthday || null;
  }

  set sex(value) {
    this._sex = value;
  }
  get sex() {
    return this._sex;
  }

  get status() {
    return this._is_active || false;
  }

  get role() {
    return this.roles.filter((role: string) => role !== 'authenticated');
  }
  get roles() {
    return this._roles;
  }

  set roles(roles: any) {
    const rest: any[] = [];
    roles = typeof roles === 'string' ? [roles] : roles || [];
    roles.map((role: string) => {
      if (!rest.includes(role)) {
        rest.push(role);
      }
    });
    this._roles = rest;
  }

  public reset() {
    // tslint:disable-next-line:no-this-assignment
    const $self = this;
    Object.assign($self, User.instanse());
  }

  /**
   * {@inheritdoc}
   */
  get accessUpdate(){ return this._accessUpdate || false;}
  /**
   * {@inheritdoc}
   */
  set accessUpdate(value){ this._accessUpdate = value || false;}


  /**
   * {@inheritdoc}
   */
  get accessDelete(){ return this._accessDelete || false;}
  /**
   * {@inheritdoc}
   */
  set accessDelete(value){ this._accessDelete = value || false;}

  // @ts-ignore
  public save({ id, image, photo, status, name, username, role, mail, phone, address, profile, ga, pixel, auth, perms, accessDelete, accessUpdate } = {}) {
    const { birthday, sex }: any = profile || {};
    this.ga = ga;
    this.pixel = pixel;

    this.id          = id;
    this.mail        = mail;
    this.name        = name;
    this.username    = username;
    this._is_active   = status;

    this.sex         = sex;
    this.birthday    = birthday;
    this.phone        = phone;
    this.roles        = role || ['anonymous'];
    this.auth         = auth;
    this.address      = address;
    this.profile      = {...profile, name};
    this.accessDelete = accessDelete
    this.accessUpdate = accessUpdate;
    this.photo        = photo
    this.image        = image
    this.perms        = perms;
    return this;
  }

  public hasPerms(perms: any) {
    const $perms = this.perms || {};
    perms = typeof perms === 'string' ? [perms] : perms || [];

    return !!perms.filter((perm: string) => $perms[perm]).length;
  }

  public hasRole(roles: any) {
    const $roles = this.roles;
    roles = typeof roles === 'string' ? [roles] : roles || [];
    return !!roles.filter((role: string) => $roles.includes(role)).length;
  }
}

export default User;

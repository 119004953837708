import React from 'react';
import ClientModule from "@gqlapp/module-client-react";
import HeadSide from "./components/head/HeadSide";
import FooterSide from "./components/FooterSide";
// @ts-ignore
import BottomComponent from "./components/bottom/BottomSide";

import WithOnToolbars from "./components/toolbars/withOnToolbars";
import WithOnUserToolbars from "./components/toolbars/WithOnUserToolbars";
import WithOnSearch from "./components/toolbars/WithOnSearch";
import WithOnConfirm from "./components/toolbars/withOnConfirm";
import WithOnMenu from "./components/toolbars/WithOnMenu";


// @ts-ignore
const Bottom = (props: any) => React.createElement(BottomComponent, {...props, className: 'bottom-toolbar'});
// @ts-ignore
const BottomSide = (props: any) => React.createElement(BottomComponent, {...props, className: 'bg-white', style: { height: '50px'}});
import TopSide from "./components/TopSide";
export default new ClientModule({
  popupComponents:[
    { component: WithOnMenu },
    { component: WithOnConfirm },
    { component: WithOnSearch },
    { component: WithOnToolbars },
    {
      positions: ['web', 'textsocial'],
      component: WithOnUserToolbars
    },
  ],
  blocks: [
    {
      positions: [ 'web.head', 'textsocial.head' ],
      component: HeadSide, weight: -1
    },
    { position: 'web.top',            component: TopSide },
    {
      positions: [ 'web.footer','textsocial.footer'],
      component: FooterSide
    },
    {
      positions: [ 'web.bottom', 'textsocial.bottom'],
      component: Bottom
    },
    {
      positions: [ 'cpanel.bottom',  'link.bottom',  'list.bottom'],
      component: BottomSide
    }
  ]
});

export default class Menu {
  public name: string;
  public items: any[];

  /**
   * instanse
   */
  public static instanse() {
    return new this();
  }
  
  /**
   * save
   */
  public save({ name, items = [] }: any) {
    this.name = name;
    this.items = items.sort((a:any = {}, b:any = {}) => a.weight - b.weight);
  }
}
import React from "react";
import compose from "recompose/compose";

export const withListProcess = (Component) => compose()(Component);


const Render = (({ children, onRenders }) => <>
  {children}
</>)


export const withRenderProcess = (Component) => ((props)=>
  React.createElement(Render, props, React.createElement(Component, props)));

const errCatch = () => ({ graphQLErrors, errorMsg, ...props } = {}) => {
	let { message, type, _errors } = errorMsg || props || {}
	let { errorMsg: eMsg } = _errors || {};
	message = message || errorMsg || eMsg || '';
	if( graphQLErrors && graphQLErrors.length){
		graphQLErrors.map(({ message })=> msg({ message: `${message}`.t(), type: type || 'danger' }))
	}
	else if(message){
		msg({ message: `${message}`.t(), type: type || 'danger'})
	}
}

export default errCatch;

export default class Video {
  public id: number;
  public title: string;
  public url: string;
  public full: string;
  public uid: number;
  public author: string;
  public category: any[];
  public tags: any[];
  public summary:string;
  public body:string;
  public video_url:string;
  public image: any;
  public created: string;
  public changed: string;
  public status: boolean = false;

  /**
   * instanse
   */
  public static instanse() {
    return new this();
  }
  
  /**
   * save
   */
  public save({ id, url, full, title, body, video_url, summary, tags, category, status, uid, author, image, created, changed }: any) {
    this.id       = id;
    this.uid      = uid;
    this.url      = url;
    this.full     = full;
    this.author   = author;
    this.title    = title;
    this.video_url= video_url;
    this.category = category;
    this.tags     = tags;
    this.summary  = summary;
    this.body     = body;
    this.image    = image || {};
    this.status   = status;
    this.created  = created;
    this.changed  = changed;
  }
}

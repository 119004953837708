import React  from 'react';
import clsx from "clsx";
import AppBar from '@mui/material/AppBar';

import Container from 'reactstrap/lib/Container';

const HeadSide = ({ className }) => {
  return <AppBar
    color="primary"
    position="relative"
    className={clsx(className, 'head-top')}
  >
    <Container fluid><div className={'header'} /></Container>
  </AppBar>
}

export default HeadSide;

import React from 'react';

import compose from "recompose/compose";
import PageLayout from '@vinhxuan/block-client-react/components/PageLayout';
import Head from "@vinhxuan/block-client-react/components/head/Head";
import User from "@vinhxuan/user-common/classes/User";
import Route from "@vinhxuan/router-common/classes/Route";

export const withRoute = (Component: any) => {
  return (({ currentUser, graphqlRoute, ...props}: any) => {

    Object.assign(props, {
      currentUser: User.instanse(currentUser),
      route: Route.instanse(graphqlRoute)
    })

    // debug('common-react', 'withRoute',{ graphqlRoute })

    const webType = props?.webType || 'web';

    const $self: any = props?.$self;

    const components = $self?.getPopup([webType]) || [];

    const withRenderProcess = (Component: any) => ((props: any)=>
      React.createElement((({ children, onGlobalRenders }) => <>
        {(onGlobalRenders && Object.keys(onGlobalRenders).length ) && Object.keys(onGlobalRenders)
          .map(key => onGlobalRenders[key])
          .map((C, key) => <C key={key} entityType={'link'} />)}
        {children}
      </>), props, React.createElement(Component, props))
    );

    const Render = compose(...components, ...(props.$self.preload || []), withRenderProcess)
    ((props)=>{
      return React.createElement(Head, props, React.createElement(PageLayout, props, React.createElement(Component, props)))
    })


    // @ts-ignore
    return React.createElement(Render, props);
  });
};

export default withRoute;

import { graphql } from 'react-apollo';
import VIDEOS_QUERY from '../graphql/videosQuery.graphql';
import { compose, withProps } from 'recompose';
import mapInput from '@gqlapp/base/utils/mapInput';

/**
 * withVideos
 * @param input
 */
export const withVideos = (input: any) => (Component: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    withProps(({ videos: { variables } }: { videos: any }) => ({ ...variables })),
    graphql(VIDEOS_QUERY, {
      options: ({ view_limit, protected: p, page, pageSize, orderBy, direction, filter }: any) => ({
        variables: { viewLimit: !!view_limit, protected: p, page, pageSize, orderBy, direction, filter }
      }),
      props({ data, ownProps: { videos }}: any) {
        let { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore } = data || {}
        videos.fetchMore = fetchMore;

        if (!loading && res) {
          videos.update(res);
        }

        return {
          loading,
          videosRefetch: refetch,
          videosSubscribeToMore: subscribeToMore,
          videosMore: fetchMore,
          videosUpdate: updateQuery,
          errors: error ? error.graphQLErrors : null
        };
      }
    })
  )(Component);

export default withVideos;

import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import Icon from '@gqlapp/base/Icon'
import Nav from 'reactstrap/lib/Nav';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import MenuItem from "@gqlapp/base/Menu/MenuItem";
import Menus from "@gqlapp/core-common/Menus";
import clsx from "clsx";
import Link from "@vinhxuan/look/Link";

const Navigation = ({ className, head, navigation, level }) =>  (<Nav className={className}>
  {navigation.map(({ className, classIconName, title, url, id, icon, children, isActive, tag, ...link }, index) => (
    <Fragment key={index}>
      <MenuItem>
        <Link to={url} title={title} className={clsx(className, 'animation d-flex align-items-center nav-link d-inline', { 'show': isActive }, { 'active': isActive })}>
          { icon && <Icon tag={tag} className={clsx(classIconName, 'link-icon d-inline tada')} classNameIcon={'icofont-1x'}>{icon}</Icon>}
          { title && <span>{`${title}`.t()}</span> }
        </Link>
        {(!!children && !!children.length) && <>
          <div className="collapse">
            <Navigation
              {...{
                head,
                navigation: children,
                level: level + 1
              }}
            />
          </div>
        </>}
      </MenuItem>
    </Fragment>
  ))}
</Nav>);

Navigation.propTypes = {
  head: PropTypes.node,
  layout: PropTypes.any,
  navigation: PropTypes.array,
  className: PropTypes.node,
  level: PropTypes.number
};

Navigation.defaultProps = {
  layout    : 'vertical',
  className : '',
  level     : 1
};

export default compose(withProps(({ menu, layout, ...props})=>{
  let navigation = [];
  const menus = new Menus({...props, settings: layout});
  try{
    menu.map(({name:title, url:{ path:url}, ...val})=>
        menus.add(Object.assign(val, {title, url}))
    );
  }catch (e) {}
  
  
  const submenu = [];
  menus.tree.map(({isActive, children, ...link}, index) => {
    children = children || [];
    if(isActive && children.length){
      children.map((menu)=> submenu.push(menu));
    }
    navigation.push(Object.assign(link, { isActive, children }));
  });
  
  return { navigation}
}))(Navigation);


import React from 'react';
import withProps from 'recompose/withProps';
import Popup from "@gqlapp/base/Modal/Popup";
import dynamic from 'next/dynamic';

const DataForm = dynamic(() => import('./Menu').then((c) => c.default));

const DataMenu = ({ isOpen, ...props }) => (
	<>{!__SERVER__ && isOpen && <DataForm {...{ ...props, isOpen }} />}</>);

export const WithOnMenu = withProps((props) => {
	let ref = React.createRef();
	let onGlobalRenders = props?.onGlobalRenders || {}
	let onActions = props?.onActions || {}

	Object.assign(onActions, {
		Menu: (val) => {
			try { ref.current.open(val); } catch (e) {}
		}
	})

	Object.assign(onGlobalRenders, {
		Menu: (rest) => (<>
			<Popup
				{...props}
				{...rest}
				ref={ref}
				component={DataMenu}
			/>
		</>)
	})

	return {
		onGlobalRenders,
		onActions
	}
});

export default WithOnMenu;

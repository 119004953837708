import Entities from '@gqlapp/core-common/Entities';
import Article from './Article';

export default class Articles extends Entities {
  constructor(props= {}) {
    super(props);
    this.interface = Article;
  }
  
  /**
   * instanse
   */
  public static instanse(props={}) {
    return (new this(props)).save([]);
  }
}

// @ts-ignore
import isEmpty from "@gqlapp/base/utils/isEmpty";

export class Route {
  public entity_type: string = '';
  public title: string = '';
  public path: string = '';
  public type_name: string = '';
  public id: number = 0;
  public hash: string = '';
  public parent_id: number = 0;
  public metatags: any[] = [];
  public hlinks: any[] = [];
  private _protected: any = {}

  /**
   * instanse
   * Module cố định không thay đổi ko sử dụng biến get và set
   */
  public static instanse(data?:any) {
    return new this().save(data || {});
  }

  public save(_data) {
    let { metatags, hlinks, id, title, path, type_name, parent_id, hash, protected: p }: any = _data || {}
    this.id = Number(id) || 0;
    this.hash = hash;
    this.parent_id = Number(parent_id) || 0;
    this.path = path;
    this.entity_type = type_name;
    this.type_name = type_name;
    this.metatags = metatags || [];
    this.hlinks = hlinks || [];
    this.protected = p;

    try {
      title = [...(metatags || [])].filter(({ key }: any) => key == 'title' || key === 'og:title').shift().value;
    } catch (e) {}

    if (!isEmpty(title)) {
      this.title = title;
    }

    return this;
  }

  get metaTitle() {
    return this.title;
  }


  /**
   *
   * @param value
   */
  set protected(value: any) {
    this._protected = value;
  }

  get protected() {
    return this._protected || {};
  }
}

export default Route;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withFormik } from "formik";

const ContentBase =
  compose(
    withProps(({ wrap, HeaderWrap, BodyWrap, FooterWrap }) => ({
      Wrap: wrap || Fragment,
      BodyWrap: BodyWrap || Fragment,
      HeaderWrap: HeaderWrap || Fragment,
      FooterWrap: FooterWrap || Fragment,
    })),
    withProps(({ Wrap, BodyWrap, HeaderWrap, FooterWrap, wrapProps, bodyProps, headerProps, footerProps, isSubmitting,...props }) => ({
      wrapProps: (typeof Wrap === 'symbol')? {}: { ...props, ...wrapProps },
      bodyProps: (typeof BodyWrap === 'symbol') ? {}: { ...props, ...bodyProps },
      headerProps:(typeof HeaderWrap === 'symbol')? {}: { ...props, ...headerProps},
      footerProps: (typeof FooterWrap === 'symbol')? {}: { ...props, ...footerProps },
      submitting: isSubmitting,
    }))
  )
  (({ wrapProps, bodyProps, headerProps, footerProps, Wrap, HeaderWrap, BodyWrap, FooterWrap, children, ...props }) => (
    <>
      <Wrap {...wrapProps}>
        <HeaderWrap {...headerProps} />
        <BodyWrap {...bodyProps}>
          {(!!children) && <>
            <>
              {typeof children === 'function' && children(props)}
              {typeof children !== 'function' && children}
            </>
          </>}
        </BodyWrap>
        <FooterWrap {...footerProps} />
      </Wrap>
    </>
  ))

const Form = ({ isFormDisable, enableReinitialize, displayName, validationSchema, handleSubmit, validate, ...props})=>{
  const FormBase = isFormDisable ? ContentBase : (withFormik({
    enableReinitialize,
    displayName: displayName || `DefaultForm${Math.random()}`,
    handleSubmit,
    validationSchema,
    validate,
    mapPropsToValues: ({ mapPropsToValues, values, ...rest }) => {
      if(typeof mapPropsToValues == 'function'){
        return mapPropsToValues({ ...rest, values })
      }else{
        return { ...values }
      }
    }
  })(ContentBase));
  return <FormBase {...props}/>
};

Form.propTypes = { children: PropTypes.any };
Form.defaultProps = { children: null, isFormDisable: false, enableReinitialize: true };


export default Form;

import React, { Fragment, useImperativeHandle, useRef} from 'react';
import { createPortal } from 'react-dom';
import clsx from "clsx";
import Box from '@mui/material/Box';
import Base from "@vinhxuan/look/Form";
import Form from 'reactstrap/lib/Form';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Button from "reactstrap/lib/Button";


/**
 * Header
 * @param disableHeader
 * @param toggle
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({ disableHeader, label })=>(<>
  {(!disableHeader) && <Box data-modal-header>
    {(!!label)&&<>{label}</>}
  </Box>}
</>)

/**
 * BodyWrap
 * @param classNameBody
 * @param disableBody
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const BodyWrap = ({ classNameBody, disableBody, children })=>(<>
  {(!disableBody)&&<Box data-modal-body>
    {children}
  </Box>}
  {(!!disableBody)&&<>
    {children}
  </>}
</>)


const Footer = (({ groupBtn, dirty, btnConfirm, disableSubmitted, submitting, classNameFooter, handleSubmit, btnCancel, ...props })=>(<>
  {(!!(btnCancel || btnConfirm || groupBtn))&&<>
    <Box data-modal-footer className={classNameFooter}>
      <Form onSubmit={handleSubmit}>
        <>
          {(!!btnCancel) && <Button
            color="secondary"
            onClick={e => {
              e.preventDefault();
              try { props.onClose(); } catch (e) { }
            }}
          >{btnCancel}</Button>}
        </>
        <>
          {(!!btnConfirm) &&
            <Button disabled={disableSubmitted && submitting} type={'submit'} color="danger">
              {` ${btnConfirm} `}
            </Button>}
        </>
        <>
          {(typeof groupBtn === 'function') && <>
            {groupBtn({...props, handleSubmit, disableSubmitted, dirty, submitting})}
          </>}
        </>
      </Form>
    </Box>
  </>}</>))


const FormConfirm = ({ formProps, ...props}) =>{
  return <Box
    data-modal-overlay
    data-modal-confirm
  >
    <Base {...{...props, ...formProps, BodyWrap }} />
  </Box>
}

const Confirm = compose(
  withProps(({ modalProps, className, id, zIndex, isOpen, toggle, centered, style }) =>
    ({ Fragment, modalProps :{ ...modalProps, style, className, id, zIndex, isOpen, toggle , centered}})),
  withProps(({ HeaderWrap, FooterWrap, onSubmit, handleSubmit }) => ({
    HeaderWrap: HeaderWrap || Header,
    FooterWrap: FooterWrap || Footer,
    formProps:{ handleSubmit: handleSubmit }
  }))
)(FormConfirm)

const Modal = React.forwardRef(
  ({ sx, append, isOpen, className, ...props}, ref) => {
    /** @type {*|(function())} */

    let timeout = null;

    const containerRef = useRef(null)
    const backdropRef = useRef(null)
    /**
     * Close Modal
     */
    function close(callbackClose = true){
      clearTimeout(timeout);
      timeout = setTimeout(()=>{ callbackClose && props?.close && props?.close() }, 300)
      let classList = containerRef.current?.classList;
      classList && classList.remove('inherit')
      classList && classList.remove('in')
    }

    /**
     * Open modal
     */
    function open(){
      let classList = containerRef.current?.classList;
      clearTimeout(timeout);
      timeout = setTimeout(()=>{
        classList && classList.add('in');
      }, 600);
    }

    useImperativeHandle(ref, () => ({
      close,
      open,
      isOpen
    }));



    /*** */
    useImperativeHandle(ref, () => ({ close, open }));

    const innerRef = (node) =>{
      if(!!node && !containerRef.current) {
        containerRef.current = node;
      }

      containerRef.current && props?.innerRef &&  props?.innerRef(containerRef.current);
    }

    /**
     * append element confirm
     */
    if(typeof append !== 'object'){
      if(typeof append === 'string'){
        append = $$(append);
      }else{
        append = $$('body > #__next > #switcher');
      }
    }

    const onClose = () =>{
      if(typeof props.onClose === 'function'){
        props.onClose();
      } else{
        close()
      }
    }

    return (<>
      {isOpen && append && createPortal(<>
        <Box
          sx={sx || {
            '--modal-popup--overlay-rounded': '10px !important',
            '[data-modal-overlay]':{
              padding: '15px'
            },
            '[data-modal-body]':{
              padding: '15px 0'
            },
            '[data-modal-footer]':{
              '.btn':{
                height: '40px',
                minWidth: '120px',
                borderRadius: '10px',
                '& + .btn': { marginLeft: '30px' },
                '&.btn-secondary':{
                  color: '#000',
                  '--btn-secondary': '#F2F2F2',
                  '--border-secondary': '#F2F2F2'
                }
              }
            }
          }}
          data-modal-popup
          ref={innerRef}
          className={clsx('confirm', className)}
        >
          <div
            key="backdrop"
            data-modal-backdrop
            onClick={onClose}
            ref={backdropRef}
          />
          <Confirm {...{...props, onClose }} />
        </Box></>, append )}
    </>)
  });


Modal.defaultProps = {
  handlers: function (){}
}

export default Modal;

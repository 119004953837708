import React from 'react';
import withProps from 'recompose/withProps';
import Popup from "@gqlapp/base/Modal/Popup";
import Confirm from '@vinhxuan/look/Confirm';

const DataConfirm = ({ isOpen, innerRef, ...props }) => (
	<>{!__SERVER__ && isOpen && <Confirm ref={innerRef} {...{ ...props, isOpen }} />}</>);

export const withOnToolbars = withProps((props) => {
	let ref = React.createRef();
	let onGlobalRenders = props?.onGlobalRenders || {}
	let onActions = props?.onActions || {}

	let Render = (rest) => (<>
		<Popup
			{...props}
			{...rest}
			ref={ref}
			component={DataConfirm}
		/>
	</>);

	let Action = (val) => (()=> {
		try { ref.current.open(val); } catch (e) {}
	})

	Object.assign(onActions, {
		confirm: Action
	})

	Object.assign(onGlobalRenders, {
		confirm: Render
	})

	return {
		onGlobalRenders,
		onActions
	}
});

export default withOnToolbars;

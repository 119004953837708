import React from 'react';

import withLoading from "@vinhxuan/utils/withLoading";
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import HomeView from "../components/HomeView";
import withArticles from "@vinhxuan/article-common/classes/withArticles";
import Articles from "@vinhxuan/article-common/classes/Articles";
import withVideos from "@vinhxuan/video-common/classes/withVideos";
import Entities from "@vinhxuan/video-common/classes/Videos";
import Container from 'reactstrap/lib/Container';
import WidgetBox from "@gqlapp/base/View/WidgetBox";

import { withListProcess, withRenderProcess } from "../helpers/ViewMediaTools";

const HomeContainer = (props)=>{
	return (<Container fluid>
		<WidgetBox className={'main-page'} color={'warning bg-white'}>
			<HomeView {...props} />
		</WidgetBox>
	</Container>)
}


const Home = compose(
	withListProcess,
	withRenderProcess,
	withProps(({ })=>{
		let articles_list = Articles.instanse({ pageSize: 3, filter: {
				"tids": [3],
				"promote": true
			}});
		let articles_more = Articles.instanse({ pageSize: 10, filter: {
				"tids": [3],
				"promote": true
			}});

		let articles_popular = Articles.instanse({ pageSize: 10, filter: {
				"tids": [34]
			}});

		return { articles_popular, articles_list, articles_more }
	}),
	withArticles(({ articles_popular })=> ({ articles: articles_popular })),
	withArticles(({ articles_list })=> ({ articles: articles_list })),
	withArticles(({ articles_more })=> ({ articles: articles_more })),
	withVideos(({})=> ({ videos: Entities.instanse({ pageSize: 6 }) })),
	/**
	 *
	 */
	withLoading
)(HomeContainer);


export default Home;

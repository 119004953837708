import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import resources from './locales';
import Route from '@vinhxuan/router/classes/Route';
import loadable from 'next/dynamic';
export default new ClientModule({
  route:{
    PageNotFound: <Route webType={'page-not-found'} component={loadable(()=>import('./PageNotFound').then((c) => c.default))} />
  },
  localization: [{ ns: '404', resources, more: ['common'] }]
});

import React from "react";
import Route from '@vinhxuan/router/classes/Route';
import ClientModule from "@gqlapp/module-client-react";
import resources from './locales';
import menu from './menu';
import List from './containers/ListContainer';
import Article from './containers/ArticleContainer';
import Action from '@vinhxuan/block-common/classes/Action';

export default new ClientModule(menu, {
  localization: [{ ns: 'article', resources, more: ['common'] }],
  route:{
    Article:           <Route component={Article} {...{ classNameOnPage: 'article', action: Action.instanse() }}/>,
    TaxonomyCategory:  <Route component={List}    {...{ classNameOnPage: 'taxonomy taxonomy-article', action: Action.instanse() }} />,
  }
});

import React from 'react';
import Container from 'reactstrap/lib/Container';
import Navigation from './Navigation';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { Menu, withMenus } from "@vinhxuan/menu-common";

const TopSide = compose(
  withMenus({ menu: Menu.instanse(), name: 'main' }),
  withProps(({ $self, pageStyle, menu: { items = [] } = {}, currentUser, ...props})=>{
    let top = items.map(({title, url, ...rest }, id )=>({ id, name: title, url, parent: [{ targetId: 0 }], ...rest }));
    return {
      top: (<Navigation className={'main-menu'} menu={top} user={currentUser} {...props} />),
    };
  }))(({ top }) =>  (
  <Container fluid className={'navigation'}>
    <div className={"navigation--inner"}>{top}</div>
  </Container>
));

export default TopSide;

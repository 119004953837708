import withUseStyles from "./Head.Styles";
import React from "react";
import clsx from "clsx";
import i18next from "i18next";
import settings from "@vinhxuan/config/settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import { Helmet } from 'react-helmet';

function Head(props) {
  const [htmlClass, setClassesName] = React.useState(null);
  let classToggle = props?.classToggle;
  const siteAction = props?.siteAction;

  classToggle = classToggle || 'with-panel-left-reveal';

  const setClasses = (value) => {
    setClassesName(htmlClass ? null : clsx('with-panel', value));
  };

  if (siteAction) {
    siteAction.callback = () => {
      try {
        setClasses(classToggle);
      } catch (e) {}
    };
  }

  // console.warn('########','route', '#########', props.route)
  const title = props.route?.metaTitle;
  const metatags = props?.route?.metatags || [];
  const hlinks = props?.route?.hlinks || [];
  const currentUser = props?.currentUser;
  const logined = currentUser && !!currentUser.id;

  let classNameOnPage = clsx(props?.classes?.root, props?.classNameOnPage, htmlClass);
  classNameOnPage = clsx(classNameOnPage, props?.webType);
  /**
   * set page classes
   */
  if (logined) {
    classNameOnPage = clsx(classNameOnPage, props?.loginClass);
  } else {
    classNameOnPage = clsx(classNameOnPage, props?.unloginedClass);
  }

  const lang = i18next.language;
  if (__SERVER__ && process.env.API_SERVER_URL) {
    settings.setJS('apiURL', process.env.API_SERVER_URL);
  }

  if (__SERVER__ && process.env.VERSION) {
    settings.setJS('version', process.env.VERSION);
  }

  if (__SERVER__ && process.env.SERVER_API) {
    settings.setJS('serverApi', process.env.SERVER_API);
  }

  if (__SERVER__ && props?.route?.origin) {
    settings.setJS('origin', props?.route?.origin);
  }

  // debug(useMediaQuery('(max-width:374px)'));
  return (
      <React.Fragment>
        <Helmet key={0} htmlAttributes={{ lang, class: classNameOnPage }}>
          <meta charSet="utf-8" />
          <meta name="theme-color" content="#ffffff" />
          {/*<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />*/}
          <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1"/>
          <meta name="apple-mobile-web-app-capable" content="yes" />

          {(!!useMediaQuery('(max-width:374px)')) && <meta name="viewport" content="width=375px, user-scalable=no"/>}
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;500&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@400;500&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Lobster:wght@400;500&display=swap" rel="stylesheet" />
          {!!title && <title>{title}</title>}
        </Helmet>

        {!!(metatags || []).length && (
            <Helmet key={1}>
              {metatags.map(({ key, value }, idx) => (
                  <meta key={idx} property={key} content={value} />
              ))}
            </Helmet>
        )}

        {!!(metatags || []).length && (
            <Helmet key={2}>
              {metatags.map(({ key, value }, idx) => (
                  <meta key={idx} name={key} content={value} />
              ))}
            </Helmet>
        )}

        {!!(hlinks || []).length && (
            <Helmet key={3}>
              {hlinks.map(({ key, value }, idx) => (
                  <link key={idx} rel={key} href={value} />
              ))}
            </Helmet>
        )}
        <Box component={'section'} sx={props?.stylesHeaders} id={'switcher'}>
          {props?.children}
        </Box>
      </React.Fragment>
  );
}

export default withUseStyles(Head)

import React from "react";

import ClientModule from '@gqlapp/module-client-react';
import resources from './locales';
import { userConfigAnonymous } from './config';
import Route from '@vinhxuan/router/classes/Route';
import View from './containers/View';
export default new ClientModule({
  route:{
    TypeIsFront:  <Route {...userConfigAnonymous} path={'/'} component={View} />
  },
  localization: [{ ns: 'home', resources, more: ['common'] }]
});

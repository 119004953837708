import React from 'react';
import NotificationProvider from 'react-notification-alert';
const ref = { loadingRef: null, notify: null };

const Notification = ({ maxSnack, children, ...props }) => (
  <>
    {typeof children === 'function' &&
      children({
        ...props,
        alert: ({ message, ...options }, customType = null) => {
          try {
            if (message) {
              options = Object.assign({
                  place: 'tr',
                  type: 'dark',
                  icon: 'now-ui-icons ui-1_bell-53',
                  zIndex: 9999999,
                  message,
                  autoDismiss: maxSnack || 5,
                },
                options
              );

              if(customType){
                options = Object.assign(options, { type: customType });
              }

              ref.notify.notificationAlert(options);
            }
          } catch (e) {
            console.error('#### Notification #######', e, ref);
          }
        },
      })}
    {typeof children !== 'function' && children}
    <NotificationProvider ref={(el) => { if(el){ ref.notify = el} }} />
  </>
);

export default Notification;

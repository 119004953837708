class Action{
  constructor() {}

  /**
   *
   */
  public static instanse() {
    return (new this());
  }

  _callback: any = ()=> { };

  set callback(value: any){
    this._callback = value
  }

  get callback(){
    return this._callback;
  }


  set set(value: any){
    this._callback = value
  }

  get get(){
    return this._callback;
  }
}

export default Action;
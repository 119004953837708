import ClientModule from "@gqlapp/module-client-react";
import storage from "@vinhxuan/storage";
import i18n from "@vinhxuan/i18n-client-react";
import Home from "@vinhxuan/home-client-react";
import Block from "@vinhxuan/block-client-react";
import PageNotFound from "@vinhxuan/page-not-found";
import User from "@vinhxuan/user-client-react";
import Router from "@vinhxuan/router-client-react";
import Article from "@vinhxuan/article-client-react";
import Video from "@vinhxuan/video-client-react";
import Photo from "@vinhxuan/photo-client-react";

const Base = new ClientModule(storage, i18n, Block,  Home, User, Article, Video, Photo, PageNotFound, Router);
export default Base;

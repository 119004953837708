import { graphql } from 'react-apollo';
import ARTICLES_QUERY from '../graphql/articlesQuery.graphql';
import { compose, withProps } from 'recompose';
import mapInput from '@gqlapp/base/utils/mapInput';

/**
 * withArticles
 * @param input
 */
export const withArticles = (input: any) => (Component: any) =>
  compose(
    withProps((props: any) => mapInput(input, props)),
    withProps(({ articles: { variables } }: { articles: any }) => ({ ...variables })),
    graphql(ARTICLES_QUERY, {
      options: ({ view_limit, protected: p, page, pageSize, orderBy, direction, filter, fetchPolicy }: any) => ({
        fetchPolicy: fetchPolicy || null,
        variables: { viewLimit: !!view_limit, protected: p, page, pageSize, orderBy, direction, filter }
      }),
      props({ data, ownProps: { articles } }: any) {
        let  { loading, res, refetch, error, updateQuery, fetchMore, subscribeToMore } = data || {}
        articles.fetchMore = fetchMore;

        if (!loading && res) { articles.update(res); }

        return {
          loading,
          articlesRefetch: refetch,
          articlesSubscribeToMore: subscribeToMore,
          articlesMore: fetchMore,
          articlesUpdate: updateQuery,
          errors: error ? error.graphQLErrors : null
        };
      }
    })
  )(Component);

export default withArticles;

import withProps from 'recompose/withProps';
import React from 'react';
let timeout = null;
const withLoading = withProps(({ loading, loadbar: { done }}) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (!loading) {
      done();
    }
  }, 100);
});

export default withLoading;

import React  from 'react';
import clsx from 'clsx';
import compose from 'recompose/compose';
import View from '@gqlapp/base/View';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import Image from 'next/image';
import Item, { Map } from '@gqlapp/base/Item';
import Link from '@vinhxuan/look/Link';

import { Fade } from 'react-slideshow-image';
import { Menu, withMenus } from "@vinhxuan/menu-common";

/**
 *
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const Contents = ({ left, articles: { items = [] }  = {}}) => {
	let datasource = [];
	items.map(( { id, image, title, summary, url }, idx) => {
		const access = { }
		try{
			image = image.map(({ alt, url, teaser }) => {
				let { url: src } = teaser || {}
				return ({ alt: alt || title, title: alt || title, src: src || url })
			});
		}catch (e) {}
		datasource.push({ id: idx, title:{ ...access, title, url, summary, image }});
	});

	const imageProps = {
		className:'shadow p-1 bg-white',
		width: 100,
		height:100,
		style:{
			minWidth: 100,
			width: 100,
			height: 'auto !important',
			textAlign: 'center'
		}
	}

	let columns = [
		{
			dataIndex: 'title',
			render: ({ title, summary, image, url }) => (<>
				<Row small>
					{(!!(left && (image || []).length)) && (<Col xs={'auto'}>
						<Map items={image}>{
							(rest)=><Link to={url}>
								<Image {...{ ...imageProps, ...rest }} />
							</Link>
						}</Map>
					</Col>)}
					<Col>
						<div className="title text-uppercase font-weight-bold"><Link to={url}>{title}</Link></div>
						<div className="summary pt-1 ellipsis ellipsis-two" dangerouslySetInnerHTML={{__html: summary}} />
					</Col>
					{(!!(!left && (image || []).length)) && (<Col xs={'auto'}>
						<Map items={image}>{
							(rest)=><Link to={url}>
								<Image {...{ ...imageProps, ...rest }} />
							</Link>
						}</Map>
					</Col>)}
				</Row>
			</>)
		}
	];

	return (<>
		<Item
			{...{
				columns,
				datasource,
				itemProps: { className: clsx('col-12', 'pt-1', 'pb-2'), style: { minHeight: '40px' } }
			}}
		/>
	</>);
}

/**
 *
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const More = ({ articles_more: { items = [] } = {} }) => {
	let datasource = [];
	items.map(( { id, image, title, summary, url }, idx) => {
		const access = { }
		datasource.push({ id: idx, more:{ ...access, title, url, summary, image }});
	});

	let columns = [{
		dataIndex: 'more',
		render: ({ title, url }) => (<>
			<div className="title font-weight-bold"><Link to={url}>{title}</Link></div>
		</>)
	}];


	return (<>
		<Item
			{...{
				columns,
				datasource,
				className: 'content-recent',
				itemProps: { className: clsx('col-12'), style: { minHeight: '40px' } },
				style:{ width: '240px' }
			}}
		/>
	</>);
}


/**
 *
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const Videos = ({ videos: { items = [] } = {} }) => {
	let datasource = [];
	items.map(( { id, image, title, url }, idx) => {
		const access = { }
		try{
			image = image.map(({ alt, url, teaser }) => {
				let { url: src } = teaser || {}
				return ({ alt: alt || title, title: alt || title, src: src || url })
			});
		}catch (e) {}
		datasource.push({ id: idx, video:{ ...access, title, url, image }});
	});


	const imageProps = {
		className:'shadow p-1 bg-white',
		width: 220,
		height:180,
		style:{
			maxWidth: '100%',
			height: 'auto !important',
			textAlign: 'center'
		}
	}

	let columns = [
		{
			dataIndex: 'video',
			render: ({ image, url }) => (<>
				<Row small>
					{(!!(image || []).length) && (<Col xs={'auto'}>
						<Map items={image}>{(rest)=>
							<Link to={url}><Image {...{ ...imageProps,...rest }} /></Link>
						}</Map>
					</Col>)}
				</Row>
			</>)
		}
	];

	return (<>
		<Item
			{...{
				columns,
				datasource,
				itemProps: { className: clsx('col-4', 'pt-1', 'pb-2'), style: { minHeight: '40px' } }
			}}
		/>
	</>);
}

/**
 *
 * @param items
 * @returns {JSX.Element}
 * @constructor
 */
const Categories = compose(withMenus({ menu: Menu.instanse(), name: 'article' }))
(({ menu: { items = [] } = {} }) => {
	let datasource = [];

	items.map(( { id, title, url:{ path: url } }, idx) => {
			const access = { }
			datasource.push({
				id: idx,
				title:{ ...access, title, url }
			});
		}
	);

	let columns = [
		{
			dataIndex: 'title',
			render: ({ title, url }) => (<><Link to={url}>{title}</Link></>),
			title: 'title'
		}
	];

	return (<>
		<Item
			{...{
				columns,
				datasource,
				itemProps: { className: 'col-12' }
			}}
		/>
	</>);
})


const HomeView = ({ links, articles_popular, articles_list, ...props }) => (<>
	<View id={'home-main'}>
		<Row style={{marginBottom: '-45px'}}>
			<Col xs={'auto'}>
				<div className={clsx('front-frame', 'clearfix')}>
					<Fade autoplay duration={2000}>
						<div className="each-fade">
							<div>
								<Image width={633} height={310} src={'http://vinhxuanhaiphong.vn/files/slide/1st.jpg'} />
							</div>
						</div>
						<div className="each-fade">
							<div>
								<Image width={633} height={310} src={'http://vinhxuanhaiphong.vn/files/slide/2nd.jpg'} />
							</div>
						</div>
						<div className="each-fade">
							<div>
								<Image width={633} height={310} src={'http://vinhxuanhaiphong.vn/files/slide/3rd.jpg'} />
							</div>
						</div>
						<div className="each-fade">
							<div>
								<Image width={633} height={310} src={'http://vinhxuanhaiphong.vn/files/slide/4th.jpg'} />
							</div>
						</div>
						<div className="each-fade">
							<div>
								<Image width={633} height={310} src={'http://vinhxuanhaiphong.vn/files/slide/5th.jpg'} />
							</div>
						</div>
					</Fade>
				</div>
			</Col>
			<Col>
				<div className="navigation-right" style={{ width: '245px', marginRight: '25px'}}>
					<div className="inner">
						<Categories {...props} />
					</div>
				</div>
			</Col>
		</Row>
		<div className={clsx('main-box', 'clearfix')}>
			<div className="main-wrapper">
				<div className="inner clearfix">
					<Row style={{ marginTop: '-80px'}}>
						<Col>
							<Row>
								<Col xs={12}>
									<h2 className="block-title"><span>{('VÕ HỌC VÀ CHÂN LÝ').t()}</span></h2>
									<Contents {...{...props, left: true, articles: articles_popular }} />
								</Col>
								<Col>
									<Col xs={12}>
										<h2 className="block-title"><span>{('Tin tức võ đường').t()}</span></h2>
										<Contents {...{...props, articles: articles_list }} />
									</Col>
								</Col>
							</Row>

						</Col>
						<Col xs={'auto'}>
							<More {...props} />
						</Col>
					</Row>
				</div>
			</div>
		</div>

		<div className={clsx('main-box', 'clearfix')} style={{ marginTop: '-50px'}}>
			<div className="main-wrapper">
				<div className="inner clearfix">
					<Row style={{ marginTop: '-80px'}}>
						<Col>
							<h2 className="block-title"><span>{('Thư viện ảnh và videos').t()}</span></h2>
							<Videos {...props}/>
						</Col>
						<Col xs={'auto'} style={{ width: '240px'}}>
							<ul className={'support'}>
								<li><em>Chủ nhiệm võ đường:</em><br />
									Võ sư <strong>Phạm Tuấn Dũng</strong><br />
									<strong>Tel</strong>:&nbsp;<a href="tel:0904347838">0904347838</a></li>
								<li><em>Đăng ký nhập học</em>:&nbsp;<br />
									<strong>Trần Quang Ngọc</strong><br />
									<strong>Tel</strong>:&nbsp;‭<a href="tel:0824163388">082 4163388</a></li>
								<li><em>Lân sư rồng:</em>&nbsp;<br />
									<strong>Mr.Đỗ Lộc</strong><br />
									<strong>Tel</strong>:&nbsp;<a href="tel:0898298008">089 8298008</a></li>
								<li><em>Võ phục:</em>&nbsp;<strong>Hoa Cúc Tàu<br />
									Tel&nbsp;</strong>: <a href="tel:01679026296">01679026296</a></li>
							</ul>
						</Col>
					</Row>
				</div>
			</div>
		</div>

	</View>
</>);

export default HomeView;
